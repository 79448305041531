<template>
    <div class="container mt-3">
        <calendar is-expanded/>
    </div>
</template>
<script>
import Calendar from 'v-calendar/lib/components/calendar.umd'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'

export default {
    components: {
        Calendar,
        DatePicker,
    },
}
</script>