<template>
    <div class="container">
        <div v-if="loading">
            <b-card class="text-center mt-4">
                <RingLoader :color="'#54f1d2'" />
            </b-card>
        </div>
        <div v-else>
            <b-card v-if="package.error" class="text-center mt-4">
                <div class="col-sm-12 col-md-12">
                    <h3 class="mb-4 text-center">{{ 'app.last_package' | trans }}</h3>
                    <p>{{ 'app.last_package_no_package' | trans }}</p><b-button :href="$root.route('packages.create')" variant="success">{{ 'packages.create' | trans }}</b-button>
                </div>
            </b-card>
            <b-card v-else border-variant="primary" class="p-4 mt-3">
                <div class="row">
                    <div class="col-sm-12 col-md-12">
                        <h3 class="mb-4 text-center">{{ 'app.last_package' | trans }}</h3>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12 col-md-6" style="border-right: 1px solid #000;">
                        <p>{{ 'packages.recipient' | trans }}: {{ package.recipient.phone }}</p>
                        <p>{{ 'services.service' | trans }}: {{ package.service.name }}</p>
                        <p v-if="package.destination">{{ 'packages.destination' | trans }}: {{ package.destination.name }}</p>
                        <p v-else>{{ 'packages.destination_address' | trans }}: {{ package.destination_address.city }}, {{ package.destination_address.street }}, {{ package.destination_address.building_number }}</p>
                    </div>
                    <div class="col-sm-12 col-md-6">
                        <h4 class="mb-4">{{ 'parcels.parcels_status' | trans }}</h4>
                        <p v-for="(parcel, index) in package.parcels">{{ index + 1}}. {{ parcel.statuses[0].name }}</p>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                package: null,
                loading: true,
            }
        },
        methods: {
            loadData: function() {
                axios.get(this.$root.route('ajax.packages.last-package-component-data'))
                .then((response) => {
                    this.package = response.data;
                    this.loading = false;
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>