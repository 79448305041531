<template>
    <div class="">
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <!--<h1 class="m-0 text-dark">{{ 'payments.payments' | trans }}</h1>-->
                        <h1 class="m-0 text-dark">{{ 'payments.balance_topup' | trans }}</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'dashboard'})">{{ 'app.home' | trans }}</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);" v-on:click="$router.push({name: 'payments-index'})">{{ 'payments.payments' | trans }}</a></li>
                            <li class="breadcrumb-item active">{{ 'payments.topup' | trans }}</li>
                        </ol>
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <div class="content">
        <hr>
        <h3 class="m-4 text-dark">{{ 'payments.choose_amount' | trans }}</h3>
            <div class="row">
                <div class="container col-3">
                    <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="AmountOption=='0'"> 
                        <h3 style="text-align: center;">100</h3>
                    </div>
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="chooseAmount(0)">
                        <h3 style="text-align: center;">100</h3>
                    </div>
                </div>
                <div class="container col-3">
                    <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="AmountOption=='1'">
                        <h3 style="text-align: center;">500</h3>
                    </div>
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="chooseAmount(1)">
                        <h3 style="text-align: center;">500</h3>
                    </div>
                </div> 
                <div class="container col-3">
                    <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="AmountOption=='2'">
                        <h3 style="text-align: center;">1000</h3>
                    </div>
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="chooseAmount(2)">
                        <h3 style="text-align: center;">1000</h3>
                    </div>
                </div>
                <div class="container col-3">
                    <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="AmountOption=='3'">
                        <the-mask class="form-control" v-model="amount" :mask="['#####']" type="text"></the-mask>
                    </div>
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="chooseAmount(3)">
                        <h3 style="text-align: center;">{{ 'payments.custom_amount' | trans }}</h3>
                    </div>
                </div>         
            </div>
            <hr>
            <h3 class="m-4 text-dark">{{ 'payments.choose_method' | trans }}</h3>
            <div class="row mb-4">
                <div class="container col-6">
                    <div class="card p-4 bg-info" v-if="method=='2'">
                        <h3 style="text-align: center;">{{ 'payments.transfer' | trans }}</h3>
                    </div>
                    <div class="card p-4" v-else v-on:click="method=2">
                        <h3 style="text-align: center;">{{ 'payments.transfer' | trans }}</h3>
                    </div>
                </div>
                <div class="container col-6">
                    <div class="card p-4 bg-info" v-if="method=='3'">
                        <h3 style="text-align: center;">DotPay</h3>
                    </div>
                    <div class="card p-4" v-else v-on:click="method=3">
                        <h3 style="text-align: center;">DotPay</h3>
                    </div>
                </div>         
            </div>
            <hr>
            <div class="row">
                <div class="col-6">
                </div>
                 <div class="col-6">
                    <b-button v-if="amount > 0 && method" type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="addBalance()">{{ 'packages.confirm' | trans }}</b-button>
                    <b-button v-else type="button" class="btn btn-warning btn-block mb-2 start-0" style="" v-b-popover.hover.top="'Some informations are missing'">{{ 'packages.confirm' | trans }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import { TheMask } from 'vue-the-mask';

export default {
    components: {
        TheMask,
    },
    data: function () {
        return {
            entity: null,
            amount: 0,
            method: null,
            AmountOption: null,
            custom: 0,
        }
    },
    props: {

    },
    methods: {
        loadEntity: function() {
            let self= this;
            axios.get(this.$root.route('ajax.entities.index'))
            .then(function (response) {
                self.entity = response.data.data;
            })
        },
        chooseAmount: function(option) {
            switch (option) {
                case 0:
                    this.amount = 100;
                    this.AmountOption = 0;
                    break;
                case 1:
                    this.amount = 500;
                    this.AmountOption = 1;
                    break;
                case 2:
                    this.amount = 1000;
                    this.AmountOption = 2;
                    break;
                case 3:
                    this.amount = 300;
                    this.AmountOption = 3;
                    break;
                default:
                    this.amount = 0;
            }
        },
        addBalance: function() {
            self = this;
			axios.post(this.$root.route('ajax.payments.store'), {
                amount: this.amount,
                payment_type: this.method,
                target: this.entity,
            })
			.then((response) => {
                if(response.status == "200") { 
                    Swal.fire({
                        icon: 'success',
                    })
                }
			})
        }
    },
    mounted() {
        this.loadEntity();
    },
}
</script>