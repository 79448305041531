<template>
    <div class="navbar-outer-container">
        <div class="container">
            <div class="row mx-0">
                <a class="navbar-logo-container">
                    <span class="logo-text">
                        <router-link class="remove-style" to="/">
                            <b>Self</b>
                            Locker
                        </router-link>
                    </span>
                </a>
                <div class="d-flex flex-direction-row flex-grow-1">
                    <div class="left-navbar d-flex flex-direction-column justify-content-center">
                        <a class="navbar-button"><router-link class="remove-style" to="/packages/create"><i class="fas fa-plus"></i>{{ 'main-navbar.send_package' | trans }}</router-link></a>
                        <a class="navbar-button"><router-link class="remove-style" to="/packages"> <i class="fas fa-box"></i> {{ 'main-navbar.packages' | trans }}</router-link></a>
                        <a class="navbar-button"><router-link class="remove-style" to="/payments"> <i class="fas fa-wallet"></i> {{ 'main-navbar.payments' | trans }}</router-link></a>
                        <a class="navbar-button"><router-link class="remove-style" to="/services"> <i class="fas fa-truck"></i> {{ 'main-navbar.services' | trans }}</router-link></a>
                    </div>
                    <div class="right-navbar ml-auto d-flex flex-direction-column justify-content-center">
                        <a v-if="entity" class="navbar-button" href="/select-entity"><i class="fas fa-briefcase "></i> {{ entity.name }}</a>
                        <a class="navbar-button"><router-link class="remove-style" to="/entities"> <i class="fas fa-fw fa-user-cog "></i> {{ 'main-navbar.settings' | trans }}</router-link></a>
                        <a v-on:click="logout" class="navbar-button" href="javascript:void(0);"> <i class="fa fa-fw fa-power-off"></i> {{ 'main-navbar.logout' | trans }}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            entity: null,
        }
    },
    methods: {
        loadEntity () {
            let self= this;
            axios.get(this.$root.route('ajax.entities.index'))
            .then(function (response) {
                self.entity = response.data.data;
            })
        },
        logout () {
            axios.post('/logout')
            .then(response => {
                location.reload();
            })
        }
    },
    mounted () {
        this.loadEntity();
    }
}
</script>

<style lang="scss" scoped>
    @import 'resources/sass/_navbar.scss';
    .remove-style {
        text-decoration: none;
        color: inherit;
    }
</style>