<template>
    <div class="container">
        <b-card v-if="loading" class="text-center mt-4">
            <RingLoader :color="'#54f1d2'" />
        </b-card>
        <b-card v-else border-variant="primary" class="text-center p-4 mt-3">
            <div v-if="services.error">
                <h3 class="mb-4">{{ 'app.top_services' | trans }}</h3>
                <p>{{ 'app.non' | trans }}</p><b-button :href="$root.route('services.index')" variant="success">{{ 'services.services' | trans }}</b-button>
            </div>
            <div v-else>
                <h3 class="mb-4">{{ 'app.top_services' | trans }}</h3>
                <h4>1. {{ services[0].service.name }}: {{ services[0].packages_count }}</h4>
                <h4 v-if="services[1]" >2. {{ services[1].service.name }}: {{ services[1].packages_count }}</h4>
                <h4 v-if="services[2]" >3. {{ services[2].service.name }}: {{ services[2].packages_count }}</h4>
            </div>
        </b-card>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                services: null,
                loading: true,
            }
        },
        methods: {
            loadData: function() {
                axios.get(this.$root.route('ajax.services.top-services-component-data'))
                .then((response) => {
                    this.services = response.data;
                    this.loading = false;
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>