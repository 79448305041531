window._ = require('lodash');

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require('axios');

import Swal from 'sweetalert2';
window.axios.interceptors.response.use(
    response => { return response },
    error => {
        console.log(error);
        if (error.response.status == 404) {
            return document.location.href ='/404';
        }
        if (error.response.status == 401) {
            return document.location.href = '/login';
        }
        if (error.response.status == 403) {
            return Swal.fire({
                icon: 'error',
                title: 'Błąd',
                text: 'Nie masz uprawnień do tego zasobu. Error: ' + error,
                showCancelButton: true,
                confirmButtonColor: '#d33',
                cancelButtonColor: '$3085d6',
                confirmButtonText: 'Zmień konto',
                focusCancel: true,
            }).then(result => {
                if (result.isConfirmed) {
                    axios.post('/logout')
                    .then( () => {
                        document.location.href = '/login';
                    });
                }
            });
        }

        throw error;
    }
);

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

window.$ = window.jQuery = require('jquery');

require('overlayscrollbars');
require('../../vendor/almasaeed2010/adminlte/dist/js/adminlte');