<template>
	<form>
		<div class="container card-body">
			<div class="form-group">
				<label for="emailInput">{{ 'entities.email' | trans }}</label>
				<input 
					v-model="email"
					:class="$v.email.$error ? 'form-control invalid' : 'form-control'" 
					:placeholder="'entities.email' | trans"
				>

				<label for="nameInput">{{ 'entities.name' | trans }}</label>
				<input 
					v-model="name" 
					:class="$v.name.$error ? 'form-control invalid' : 'form-control'" 
					:placeholder="'entities.name' | trans"
				>

				<label>{{ 'entities.phone' | trans }}</label>
				<vue-tel-input
					:class="!phoneValid && $v.$error ? 'invalid' : ''" 
					:inputOptions="inputOptions" 
					@input="onInput" v-model="phoneModel">
				</vue-tel-input>

				<label for="country_idInput">{{ 'entities.country_id' | trans }}</label>
                <country-picker-component
					v-model="country"
					:stateClassName="$v.country.$error ? 'invalid' : ''">
				</country-picker-component>

				<label for="province_idInput">{{ 'entities.province_id' | trans }}</label>
				<province-picker-component 
					v-model="province"
					:disabled="country ? false : true"
					:country_id="country ? country.id : null">
				</province-picker-component>
				
				<label for="cityInput">{{ 'entities.city' | trans }}</label>
				<input 
					v-model="city" 
					:class="$v.city.$error ? 'form-control invalid' : 'form-control'" 
					:placeholder="'entities.city' | trans"
				>
				
				<label for="streetInput">{{ 'entities.street' | trans }}</label>
				<input
					v-model="street" 
					:class="$v.street.$error ? 'form-control invalid' : 'form-control'" 
					:placeholder="'entities.street' | trans"
				>
				
				<label for="buildingNumberInput">{{ 'entities.building_number' | trans }}</label>
				<input
					v-model="buildingNumber" 
					:class="$v.buildingNumber.$error ? 'form-control invalid' : 'form-control'" 
					:placeholder="'entities.building_number' | trans"
				>
				
				<label for="apartmentNumberInput">{{ 'entities.apartment_number' | trans }}</label>
				<input 
					v-model="apartmentNumber" 
					class="form-control center-block" 
					:placeholder="'entities.apartment_number' | trans"
				>
				
				<label for="zipCodeInput">{{ 'entities.zip_code' | trans }}</label>
				<input 
					v-model="zipCode" 
					:class="$v.zipCode.$error ? 'form-control invalid' : 'form-control'"
					:placeholder="'entities.zip_code' | trans"
				>

				<label for="vatNumberInput">{{ 'entities.vat_number' | trans }}</label>
				<the-mask 
					:class="$v.vatNumber.$error ? 'form-control invalid' : 'form-control'" 
					v-model="vatNumber" 
					:placeholder="'entities.vat_number' | trans" 
					:mask="['#########']" 
					type="text">
				</the-mask>
				
				<label for="regonNumberInput">{{ 'entities.regon_number' | trans }}</label>
				<the-mask 
					:class="$v.regonNumber.$error ? 'form-control invalid' : 'form-control'" 
					v-model="regonNumber" 
					:placeholder="'entities.regon_number' | trans" 
					:mask="['## ###### #']" 
					type="text">
				</the-mask>
				
				<label for="nipNumberInput">{{ 'entities.nip_number' | trans }}</label>
				<the-mask
					:class="$v.nipNumber.$error ? 'form-control invalid' : 'form-control'" 
					v-model="nipNumber" 
					:placeholder="'entities.nip_number' | trans" 
					:mask="['PL ### ###### #']" 
					type="text">
				</the-mask>
				
				<label for="krsNumberInput">{{ 'entities.krs_number' | trans }}</label>
				<the-mask 
					:class="$v.krsNumber.$error ? 'form-control invalid' : 'form-control'" 
					v-model="krsNumber" 
					:placeholder="'entities.krs_number' | trans" 
					:mask="['##########']" 
					type="text">
				</the-mask>
			</div>
			<div class="row mt-4">
				<div class="col-4 d-flex">
					<b-button class="ml-auto" variant="outline-primary" :href="$root.route('select-entity.index')">{{ 'app.back' | trans }}</b-button>
				</div>
				<div class="col-4 d-flex">
				</div>
				<div class="col-4 d-flex">
					<b-button variant="outline-primary" v-on:click="postForm">{{ 'entities.create' | trans }}</b-button> <!--Checking if every value is at least filled-->
				</div>
			</div>
		</div> <!--<div class="container"> -->
	</form> 
</template>

<script>
import { email, required, minLength } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import { TheMask } from 'vue-the-mask';
import { VueTelInput } from 'vue-tel-input'
import CountryPickerComponent from '../../pickers/CountryPickerComponent';
import ProvincePickerComponent from '../../pickers/ProvincePickerComponent';

export default {
	components: {
		TheMask,
		VueTelInput,
		CountryPickerComponent,
		ProvincePickerComponent,
	},
	data () {
		return {
			name: "",
			email: "",
			phone: "",
			phoneValid: false,
			phoneModel: "",
			inputOptions: {
				placeholder: "",
			},
			province: "",
			country: "",
			city: "",
			street: "",
			buildingNumber: "",
			apartmentNumber: "",
			zipCode: "",
			vatNumber: "",
			regonNumber: "",
			nipNumber: "",
			krsNumber: "",
		}
	},
	setup: () => ({ $v: useVuelidate() }),
	validations () {
		return {
			name: { required },
			email: { required, email },
			country: { required },
			phone: { required },
			city: { required },
			street: { required },
			buildingNumber: { required },
			zipCode: { required },
			vatNumber: { required, minLength: minLength(9) },
			regonNumber: { required, minLength: minLength(9) },
			nipNumber: { required, minLength: minLength(10) },
			krsNumber: { required, minLength: minLength(10) },
		}
	},
	methods: {
		onInput: function(formattedNumber, phoneObject) {
			this.phoneValid = false;
			this.phone = phoneObject.number;
			if (phoneObject.valid == true) this.phoneValid = true;
		},
		postForm: function() {
		this.$v.$touch();
		if (this.$v.$invalid) {
			Swal.fire({
				icon: 'error',
				title: 'Not valid form',
			})
			return;
		} 
		self = this;
		axios.post(this.$root.route('ajax.entities.store'), {
			name: this.name,
			email: this.email,
			phone: this.phone,
			province_id: this.province ? this.province.id : null,
			country_id: this.country.id,
			city: this.city,
			street: this.street,
			building_number: this.buildingNumber,
			apartment_number: this.apartmentNumber,
			zip_code: this.zipCode,
			vat_number: this.vatNumber,
			regon_number: this.regonNumber,
			nip_number: this.nipNumber,
			krs_number: this.krsNumber,
			type: "other"
		}, 
		)
		.then((response) => {
			Swal.fire({
				icon: 'success',
			})
			window.location = response.data.redirect;
		})
		.catch(error =>{  //fail
			Swal.fire({
				icon: 'error',
				title: 'Something went wrong',
			})
        })
		},
	},
	mounted() {
		this.inputOptions.placeholder = this.$options.filters.trans('entities.phone');
	},
}
</script>
<style scoped lang="scss">
.invalid {
	border-color: red;
}
</style>