<template>
    <div>
        <!--Recipient-->
        <div class="content" v-if="progress == 1">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_recipient' | trans }}</h3>
            <div class="row">
                <div class="col-md-6 col-sm-12">
                    <div class="card p-4">
                            <div class="form-group" style="max-width: 100%;">
                                <label>{{ 'packages.recipient_phone' | trans }}</label>
                                <vue-tel-input class="form-control" @input="onInput" v-model="phoneModel"></vue-tel-input>
                            </div>
                    </div>
                </div>
                <div class="col-md-6 col-sm-12">
                    <div class="card p-4">
                        <div class="form-group" style="max-width: 100%;">
                            <label for="">{{ 'packages.recipient_email' | trans }}</label>
                            <input v-model="email" type="text" class="form-control" :placeholder="'packages.email_optional' | trans">                      
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progressChange(-1)">{{ 'packages.back' | trans }}</b-button>
                </div>
                <div class="col-4 mt-4">   
                </div>
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-block mb-2 start-0" :disabled="!(emailValid && phoneValid || email.length == 0 && phoneValid)"  v-bind:class="{ 'btn-warning': !(emailValid && phoneValid || email.length == 0 && phoneValid), 'btn-success': (emailValid && phoneValid || email.length == 0 && phoneValid) }" style="" v-on:click="progressChange(1)">{{ 'packages.next' | trans }}</b-button>
                </div>
            </div>
        </div>
        <!--Services-->
        <div class="content" v-if="progress == 2">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_service' | trans }}</h3>
            <div class="row">
                <div class="col-4" v-for="item in services">
                    <div class="card bg-info p-4" v-if="service && item.uuid == service.uuid">
                        <h4>{{ item.name }}</h4>
                        <p>{{ 'services.price' | trans}}: {{ item.price }}</p>
                        <p v-if="item.parcel_limit == 0">{{ 'services.parcel_limit' | trans}}: {{ 'services.none' | trans}}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans}}: {{ item.parcel_limit }}</p>
                    </div>
                    <div class="card p-4" v-on:click="chooseService(item)" v-else>
                        <h4>{{ item.name }}</h4>
                        <p>{{ 'services.price' | trans}}: {{ item.price }}</p>
                        <p v-if="item.parcel_limit == 0">{{ 'services.parcel_limit' | trans}}: {{ 'services.none' | trans}}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans}}: {{ item.parcel_limit }}</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progressChange(-1)">{{ 'packages.back' | trans }}</b-button>
                </div>
                <div class="col-4 mt-4">
                    
                </div>
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-block mb-2 start-0" :disabled="!service"  v-bind:class="{ 'btn-warning': !service, 'btn-success': service }" style="" v-on:click="progressChange(1)">{{ 'packages.next' | trans }}</b-button>
                </div>
            </div>
        </div>
        <!--Machines-->
        <div class="content" v-if="progress == 3">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_machine' | trans }}</h3>
            <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-6">
                    <div class="card p-4 h-100">
                        <div class="form-group">
                            <label for="">{{ 'machines.destination' | trans }}</label>
                            <machine-picker-component :selectedMachine="destination" :service_destination="service" v-model="destination" name="destination-modal"></machine-picker-component>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progressChange(-1)">{{ 'packages.back' | trans }}</b-button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                   <b-button type="button" class="btn btn-block mb-2 start-0" :disabled="!destination"  v-bind:class="{ 'btn-warning': !destination, 'btn-success': destination }" style="" v-on:click="progressChange(1)">{{ 'packages.next' | trans }}</b-button>
                </div>
            </div>
        </div>
        <!--Parcels-->
        <div class="content" v-if="progress == 4">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.add_parcels' | trans }}</h3>
            <div class="row">
                <div class="col-4">
                    <div class="card p-4">
                        <h4>{{ 'services.service' | trans}}</h4>
                        <p>{{ service.name }}</p>
                        <p>{{ 'services.price' | trans}}: {{ service.price }}</p>
                        <p v-if="service.parcel_limit == 0">{{ 'services.parcel_limit' | trans}}: {{ 'services.none' | trans}}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans}}: {{ service.parcel_limit }}</p>
                    </div>
                </div>
                <div class="col-md-8"> 
                    <button type="button" class="btn btn-info btn-outline btn-block mb-2" v-on:click="createParcel()">{{ 'parcels.add_parcel' | trans }} <i class="fas fa-plus"></i></button>
                    <div class="container p-0 m-0 " style="min-height: 25rem;">
                        <div class="accordion" role="tablist" >
                            <b-card no-body class="mb-1" style="padding 0 !important;" v-for="(element, index) in parcels">
                                <b-card-header header-tag="header" class="p-0" role="tab">
                                    <div class="row container pr-0 pl-0 mr-0 ml-0">
                                        <b-button variant="outline-info" class="col-10" block v-b-toggle="_uid + 'accordion' + index" >Parcel {{ index +1 }} <i v-if="element.name && element.weight && element.gauge" class="fas fa-check-circle"></i><i v-else class="fas fa-exclamation-circle"></i></b-button><b-button variant="warning" v-on:click="deleteParcel(index)" class="col-2"><i class="fas fa-trash-alt"></i></b-button>
                                    </div>
                                </b-card-header>
                                <b-collapse :id="_uid + 'accordion' + index" accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <div class="row">
                                            <div class="form-group">
                                                <label for="">{{ 'parcels.name' | trans }}</label>
                                                <input v-model="element.name" type="text" class="form-control">
                                            </div>
                                            <div class="form-group">
                                            <div style="display: inline-block; position: relative;">
                                                <label for="">{{ 'parcels.weight' | trans }}</label>
                                                <the-mask @change.native="weightChange(element)" class="form-control" v-model="element.weight" :mask="['YYYYYN']" :tokens="customTokens" type="text"></the-mask>
                                                <!--<input type="number"  v-model="element.weight" class="form-control">-->
                                                <span id="kg-suffix">kg</span>
                                            </div>
                                            </div>
                                        </div>
                                        <hr>
                                        <h4 style="text-align: center;">{{ 'parcels.gauges' | trans }}</h4>
                                        <div class="row">
                                            <div class="col-4" v-for="item in gauges">
                                                <div class="card bg-info p-4" v-if="element.gauge_uuid == item.uuid">
                                                    <h4>{{ item.name }}</h4>
                                                    <p>{{ 'parcels.gauge_price' | trans }}: {{ item.price }}</p>
                                                    <p>{{ 'parcels.gauge_max_weight' | trans }}: {{ item.max_weight }}kg</p>
                                                </div>
                                                <div class="card p-4" v-else v-on:click="changeParcelGauge(element, item)">
                                                    <h4>{{ item.name }}</h4>
                                                    <p>{{ 'parcels.gauge_price' | trans }}: {{ item.price }}</p>
                                                    <p>{{ 'parcels.gauge_max_weight' | trans }}: {{ item.max_weight }}kg</p>
                                                </div>
                                            </div>
                                        </div>  
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </div>        
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progressChange(-1)">{{ 'packages.back' | trans }}</b-button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                   <b-button type="button" class="btn btn-block mb-2 start-0" :disabled="!parcelsValid"  v-bind:class="{ 'btn-warning': !parcelsValid, 'btn-success': parcelsValid }" style="" v-on:click="progressChange(1)">{{ 'packages.next' | trans }}</b-button>
                </div>
            </div>
        </div>
        <!--Payment-->
        <div class="content" v-if="progress == 5">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.choose_payment' | trans }}</h3>
            <div class="row">
                <div class="col-md-4">
                    <div class="card p-4">
                        <h4>{{ 'payments.payment' | trans}}</h4>
                        <p>{{ 'services.service' | trans}}: {{ service.price }}</p>
                        <p>{{ 'parcels.parcels' | trans}}: {{ parcelsTotalPrice }}</p>
                        <h4>{{ 'payments.payment_summ' | trans }}: {{ totalPrice }}</h4>
                    </div>
                </div>
                <div class="col-md-8" style="text-align: center;">
                    <div class="row">
                        <div class="container col-3">
                            <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="paymentType=='1'">
                                <h3 style="text-align: center;">{{ 'payments.balance' | trans }}</h3>
                            </div>
                            <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="choosePaymentType(1)">
                                <h3 style="text-align: center;">{{ 'payments.balance' | trans }}</h3>
                            </div>
                        </div>
                        <div class="container col-3">
                            <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="paymentType=='2'">
                                <h3 style="text-align: center;">{{ 'payments.transfer' | trans }}</h3>
                            </div>
                            <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="choosePaymentType(2)">
                                <h3 style="text-align: center;">{{ 'payments.transfer' | trans }}</h3>
                            </div>
                        </div>
                        <div class="container col-3">
                            <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="paymentType=='3'">
                                <h3 style="text-align: center;">DotPay</h3>
                            </div>
                            <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="choosePaymentType(3)">
                                <h3 style="text-align: center;">DotPay</h3>
                            </div>
                        </div>
                        <div class="container col-3">
                            <div class="card p-4 bg-info" style="flex-wrap: wrap; height: 100%;" v-if="paymentType=='4'">
                                <h3 style="text-align: center;">{{ 'payments.machine' | trans }}</h3>
                            </div>
                            <div class="card p-4" style="flex-wrap: wrap; height: 100%;" v-else v-on:click="choosePaymentType(4)">
                                <h3 style="text-align: center;">{{ 'payments.machine' | trans }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progressChange(-1)">{{ 'packages.back' | trans }}</b-button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                     <b-button type="button" class="btn btn-block mb-2 start-0" :disabled="paymentType == null"  v-bind:class="{ 'btn-warning': paymentType == null, 'btn-success': paymentType != null }" style="" v-on:click="progressChange(1)">{{ 'packages.next' | trans }}</b-button>
                </div>
            </div>
        </div>
        <!--Summary-->
        <div class="content" v-if="progress == 6">
        <hr>
        <h3 class="m-0 p-4 text-dark">{{ 'packages.summary' | trans }}</h3>
            <div class="row">
                <div class="col-3 mt-4">
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                        <h4>{{ 'packages.recipient' | trans }}: </h4>
                        <p>{{ 'entities.email' | trans }}: {{ email }}</p>
                        <p>{{ 'entities.phone' | trans }}: {{ phone }}</p>
                    </div>
                </div>
                <div class="col-3 mt-4">
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                        <h4>{{ 'services.service' | trans }}</h4>
                        <p>{{ service.name }}</p>
                        <p>{{ 'services.price' | trans }}: {{ service.price }}</p>
                        <p v-if="service.parcel_limit == 0">{{ 'services.parcel_limit' | trans }}: {{ 'services.none' | trans }}</p>
                        <p v-else>{{ 'services.parcel_limit' | trans }}: {{ service.parcel_limit }}</p>
                    </div>
                </div>
                <div class="col-3 mt-4">
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                        <div v-if="origin"> 
                            <h4>{{ 'machines.origin' | trans }}: </h4>
                            <p>{{ origin.name }}</p>
                        </div>
                        <h4>{{ 'machines.destination' | trans }}: </h4>
                        <p>{{ destination.name }}</p>
                    </div>
                </div>
                <div class="col-3 mt-4">
                    <div class="card p-4" style="flex-wrap: wrap; height: 100%;">
                        <h4>{{ 'payments.payment' | trans }}: </h4>
                        <p v-if="paymentType == 1">{{ 'payments.balance' | trans }}</p>
                        <p v-if="paymentType == 2">{{ 'payments.transfer' | trans }}</p>
                        <p v-if="paymentType == 3">DotPay</p>
                        <p v-if="paymentType == 4">{{ 'payments.machine' | trans }}</p>
                    </div>
                </div>
                
            </div>
            <h3 class="m-0 p-4 text-dark mt-4">{{ 'packages.parcels' | trans }}</h3>
            <div class="row mt-4">
                <div class="col-3" v-for="item in parcels">
                    <div class="card p-4" style="flex-wrap: wrap; display:">
                        <p>{{ 'parcels.name' | trans }}: {{ item.name }}</p>
                        <p>{{ 'parcels.weight' | trans }}: {{ item.weight }}</p>
                        <p>{{ 'parcels.gauge' | trans }}: {{ item.gauge.name }}</p>
                    </div>
                </div>
            </div>
            <hr>
            <div class="row">
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="progressChange(-1)">{{ 'packages.back' | trans }}</b-button>
                </div>
                <div class="col-4 mt-4">
                </div>
                <div class="col-4 mt-4">
                    <b-button type="button" class="btn btn-success btn-block mb-2 start-0" style="" v-on:click="sendPackage()">{{ 'packages.create' | trans }}</b-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { TheMask } from 'vue-the-mask';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import 'sweetalert2/src/sweetalert2.scss';
import MachinePickerComponent from '../../pickers/MachinePickerComponent.vue';
import { VueTelInput } from 'vue-tel-input';

export default {
    components: {
        TheMask,
        MachinePickerComponent,
        VueTelInput,
    },
    data: function () {
        return {
            email: "",
            phoneValid: false,
            phoneModel: "",
            phone: "",
            service: null,
            services: null,
            gauges: [],
            destination: null,
            parcels: [],
            index: 0,
            parcelsValid: false,
            progress: 1,
            paymentType: null,
            serviceType: 3,
            parcelsTotalPrice: 0,
            totalPrice: 0,
            buttonSuccess: 'btn-success',
            buttonWarning: 'btn-warning',
            customTokens: {
                Y: {pattern: /[0-9.-.]/},
                N: {pattern: /[0-9]/}
            },
            test: /^[^\s@]+@[^\s@]+$/,
        }
    },

    computed: {
        emailValid() {
            return this.test.test(this.email);
        }
	},
    methods: {
        onInput: function(formattedNumber, phoneObject) {
            this.phoneValid = false;
            this.phone = phoneObject.number;
            if (phoneObject.valid == true) this.phoneValid = true;
        },
        weightChange: function(parcel) {
            if (parcel.gauge && parseFloat(parcel.weight) > parseFloat(parcel.gauge.max_weight)) {
                parcel.gauge_uuid = "";
                parcel.gauge = null;
                 Swal.fire({
                    icon: 'error',
                    title: 'Parcel is too heavy for choosen gauge',
                })
            };
        },
        parcelsPrice: function() {
            this.parcelsTotalPrice = 0;
            for (var i = 0; i < this.parcels.length; i++) {
                this.parcelsTotalPrice += parseFloat(this.parcels[i].gauge.price);
            }
            this.totalPrice = parseFloat(this.service.price) + parseFloat(this.parcelsTotalPrice);
        },
        changeParcelGauge: function(parcel, gauge) {
            if(parseFloat(parcel.weight) > parseFloat(gauge.max_weight)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Parcel is too heavy for choosen gauge',
                })
            }
            else {
                parcel.gauge_uuid = gauge.uuid;
                parcel.gauge = gauge;
                this.parcelsPrice();
            }
        },
        chooseService: function(item) {
            this.service = item;
            this.origin = null;
            this.gauges = [];
            this.destination = null;
            this.payment_type = null;
            this.parcels = [];
            this.loadGauges();
        },
        progressChange: function(value) {
            this.progress += value;
            this.$emit('progress', this.progress);  
        },
        loadServices: function() {
            let self = this;
            axios.get(this.$root.route('ajax.services.index'), {
                params: {
                    'type': self.serviceType,
                }
                })
                .then(function (response) {
                    self.services = response.data.data;
                }) 
        },
        loadGauges: function() {
            let self = this;
            axios.get(this.$root.route('ajax.gauges.index'), {
                params: {
                    'filter[service_uuid]': self.service.uuid,
                    // filter[max_width] : element.width
                }
                })
                .then(function (response) {
                    self.gauges = response.data.data;
                })
        },
        sendPackage: function () {
            self = this;
            let province_id = null;
            if (this.province) {
                province_id = this.province.id;
            }
			axios.post(this.$root.route('ajax.packages.store'), {
                email: this.email,
                phone: this.phone,
                destination_uuid: this.destination.uuid,
                parcels: this.parcels,
                payment_type: this.paymentType,
                service_uuid: this.service.uuid,
                payment_stage: 1,
            })
			.then((response) => {
                Swal.fire({
                    icon: 'success',
                    title: 'Package created',
                })
			})
			.catch(error =>{  //fail
                Swal.fire({
                    icon: 'error',
                    title: error.response.data.message,
                })
            })
		},
        createParcel: function() {
            if (this.service.parcel_limit != 0){
                if (this.parcels.length < this.service.parcel_limit) {
                    var obj = {
                        name: "Parcel",
                        weight: "",
                        gauge_uuid: "",
                        gauge: null,
                        index: this.index,
                    };
                    this.parcels.push(obj);
                    this.index++;
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'This service has limit',
                    })
                }
            } 
            else {
                var obj = {
                    name: "Parcel",
                    weight: "",
                    gauge_uuid: "",
                    gauge: null,
                    index: this.index,
                };
                this.parcels.push(obj);
                this.index++;
            }
        },
        deleteParcel: function(parcelIndex) {
            this.parcels.splice(parcelIndex, 1)
        },
        checkIfParcelsOk: function() {
            this.parcelsValid = true;
            if (this.parcelsValid) {
                if (this.parcels.find(element => element.name.length == 0) != undefined) this.parcelsValid = false;
                if (this.parcels.find(element => element.weight.length == 0) != undefined) this.parcelsValid = false;
                if (this.parcels.find(element => element.gauge_uuid.length == 0) != undefined) this.parcelsValid = false;
            }
            
        },
        choosePaymentType: function(payment) {
            this.paymentType = payment;
        },
    },
    watch: {
        parcels:  {
            handler: function(){
                if (this.parcels.length)
                    this.checkIfParcelsOk();

            },
            deep: true
        },
        service: function() {
            this.parcels = [];
        },
    },
    mounted() {
        this.loadServices();
    } 
}
</script>

<style>
#kg-suffix {
    position: absolute;
    left: 100%;
    top: 55%;
    color: #555;
    padding-left: 5px;
    font: inherit;
}
</style>