<template>
    <div class="container">
        <b-card v-if="loading" class="text-center mt-4">
            <RingLoader :color="'#54f1d2'" />
        </b-card>
        <div v-else>
            <b-card v-if="chartData.datasets[0].data[0] || chartData.datasets[0].data[1]" border-variant="primary" class="text-center p-4 mt-3">
                <h3 class="mb-4">{{ 'app.package_amount' | trans }}</h3>
                <pie-chart :data="chartData" :options="chartOptions"></pie-chart>
            </b-card>
            <b-card v-else border-variant="primary" class="text-center p-4 mt-3">
                <h3 class="mb-4">{{ 'app.package_amount' | trans }}</h3>
                <p>{{ 'app.last_package_no_package' | trans }}</p><b-button :href="$root.route('packages.create')" variant="success">{{ 'packages.create' | trans }}</b-button>
            </b-card>
        </div>
    </div>
</template>
<script>
import PieChart from './PieChart.vue';
export default {
    components: {
        PieChart,
    },
    data: function() {
        return {
            loading: true,
            chartOptions: {
                hoverBorderWidth: 20
            },
            chartData: {
                hoverBackgroundColor: "red",
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        label: "Data One",
                        backgroundColor: ["#41B883", "#E46651", "#00D8FF"],
                        data: [0, 0],
                    }
                ]
            }
        }
    },
    methods: {
        loadData: function() {
            axios.get(this.$root.route('ajax.packages.package-amount-component-data'), { 
            })
            .then((response) => {
                this.chartData.datasets[0].data = response.data;
                this.loading = false;
            })
        }
    },
    mounted() {
        this.loadData();
        this.chartData.labels.push(this.$options.filters.trans('packages.outgoing'));
        this.chartData.labels.push(this.$options.filters.trans('packages.incoming'));
    }
}
</script>