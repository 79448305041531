<template>
    <div class="container">
        <b-card v-if="loading" class="text-center mt-4">
            <RingLoader :color="'#54f1d2'" />
        </b-card>
        <div v-else>
            <b-card v-if="paymentsTotal.length" border-variant="primary" class="text-center p-1 mt-3">
                <h3 class="mb-2">{{ 'payments.payments' | trans }}</h3>
                <chart-component :legendDisplay="false" :dataSetArray="dataSetArray" :labels="labels"/>
            </b-card>
            <b-card v-else border-variant="primary" class="text-center p-1 mt-3">
            <h3 class="mb-2">{{ 'payments.payments' | trans }}</h3>
                <p>{{ 'app.non' | trans }}</p>
            </b-card>
        </div>
    </div>
</template>
<script>
import ChartComponent from '../../../components/ChartComponent.vue';

export default {
    components: {
        ChartComponent,
    },
    data: function() {
        return {
            loading: true,
            paymentsTotal: null,
            chartColor: '#33E3FF',
            payments:[0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            dataSetArray: [],
            labels: [],
            currentMonth: null,
        }
    },
    methods: {
        parseDataForPaymentGraph: function() {
            for (var j = 0; j < this.paymentsTotal.length; j++){
                // rearrange the values, the value for the current month is the last
                this.payments[this.$root.changeMonthToRelative(parseInt(this.paymentsTotal[j].date_part) + this.currentMonth)] = this.paymentsTotal[j].amount_sum;
            }
            this.dataSetArray.push({
                label: [this.$options.filters.trans('payments.all_payments')],
                backgroundColor: this.chartColor,
                data: this.payments,
            })
            this.loading = false;
        },
        loadData: function() {
            axios.get(this.$root.route('ajax.payments.total-graph-data'), { 
            })
            .then((response) => {
                this.paymentsTotal = response.data;
                this.parseDataForPaymentGraph();
            })
        },
        fillMonthsArray: function() {
            this.labels = this.$root.getMonthsArray();
            
            var tmpLabels = [];
            // we rearrange the array, current month is last
            for (let i = 0; i < this.labels.length; i++) {
                tmpLabels[this.$root.changeMonthToRelative(i + this.currentMonth + 1)] = this.labels[i];
            }
            this.labels = tmpLabels;
            this.loadData();
        },
    },
    mounted() {
        this.currentMonth = new Date().getMonth();
        this.fillMonthsArray();
    }
}
</script>