<template>
    <div class="container">
        <b-card v-if="!entity" class="text-center mt-4">
            <RingLoader :color="'#54f1d2'" />
        </b-card>
        <b-card v-else class="p-4 mt-3">
            <h3 class="mb-4">{{ 'app.balance' | trans }}: {{ entity.balance }}</h3>
            <b-button v-on:click="$router.push({ name: 'payments-create'})" block variant="primary">{{ 'payments.add_balance' | trans }} <i class="fas fa-plus"></i></b-button>
        </b-card>
    </div>
</template>
<script>
export default {
    data: function() {
        return {
            loading: true,
            entity: null,
        }
    },
    methods: {
        loadData: function() {
            axios.get(this.$root.route('ajax.entities.index'), { 
            })
            .then((response) => {
                this.entity = response.data.data
            })
        }
    },
    mounted() {
        this.loadData();
    }
}
</script>