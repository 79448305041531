<template>
    <div class="container">
        <b-card v-if="loading" class="text-center mt-4">
            <RingLoader :color="'#54f1d2'" />
        </b-card>
        <div v-else>
            <b-card style="border-style: solid; border-width: 1px; border-color: orange;" v-if="status == 'awaiting'" variant="outline-danger" class="text-center p-4">
                <h4>{{ quantity[0].count }} {{ 'packages.parcels' | trans }} <br> {{ 'parcels.' + status | trans }}</h4>
            </b-card>
            <b-card style="border-style: solid; border-width: 1px; border-color: aqua;" v-if="status == 'on_way'" variant="outline-danger" class="text-center p-4">
                <h4>{{ quantity[0].count }} {{ 'packages.parcels' | trans }} <br> {{ 'parcels.' + status | trans }}</h4>
            </b-card>
            <b-card style="border-style: solid; border-width: 1px; border-color: green;" v-if="status == 'pickup'" variant="outline-danger" class="text-center p-4">
                <h4>{{ quantity[0].count }} {{ 'packages.parcels' | trans }} <br> {{ 'parcels.' + status | trans }}</h4>
            </b-card>
            <b-card style="border-style: solid; border-width: 1px; border-color: red;" v-if="status == 'takeout'" variant="outline-danger" class="text-center p-4">
                <h4>{{ quantity[0].count }} {{ 'packages.parcels' | trans }} <br> {{ 'parcels.' + status | trans }}</h4>
            </b-card>
        </div>
    </div>
</template>
<script>
    export default {
        data: function() {
            return {
                quantity: null,
                loading: true,
            }
        },
        props: {
            status: null,
        },
        methods: {
            loadData: function() {
                axios.get(this.$root.route('ajax.parcels.status-component-data'), { 
                    params: {
                        'status': this.status,
                    }
                })
                .then((response) => {
                    console.log(response.data);
                    this.loading = false;
                    this.quantity = response.data;
                })
            }
        },
        mounted() {
            this.loadData();
        }
    }
</script>